// =============================================================================
// Version   : 1.0.0
// Created : December 2017 by onepixel & wonderweb
// Project   : Le Courrier
// -----------------------------------------------------------------------------
// Core JS
// =============================================================================

/*===================================
=            Global Vars            =
===================================*/
var baseUrl = 'https://lecourrier.ch/',
	baseFontSize = 16;

/*==========================================================
=             Initialization                               =
==========================================================*/
(function init($){

	'use strict';

	/*==========================================================
	=        Declare Tools & Functions                         =
	==========================================================*/

	// SVG / PNG Fallback
	function svgPngFallback(){
	    if(!Modernizr.svg) {
	    	$('img[src*="svg"]').attr('src', function () {
	    		return $(this).attr('src').replace('.svg', '.png');
	    	});
	    }
	}

	function getElementStyleValue(element, property){
		var value = $(element).css(property).replace("px", "");
		return parseInt(value);
	}

	//////////////////////////////////////////////////////////
	// Set header Site Wrapper height when fixed children 	//
	/////////////////////////////////////////////////////////
	function setHeaderSiteHeight(){
		var $siteHeader = $('.js-siteHeader'),
			$headHeight = $('.js-siteHeaderHead').outerHeight(),
			$navHeight = $('.js-siteHeaderNav').outerHeight();
		$siteHeader.css({
		  'height': $headHeight + $navHeight + 'px'
		});
	}

	//////////////
	// Mini-Nav //
	//////////////
	function miniNav(){
		var prev = 400,
			$window = $(window),
			$nav = $('.js-navPrimary');

		$(window).scroll(function() {
			var scrollTop = ( $window.scrollTop() > 400 ) ? $window.scrollTop() : 0;
			// console.log('prev: ' + prev);
			// console.log('scrollTop: ' + scrollTop);

			// show/hide on scroll
			if($nav.hasClass('is-stuck')){
				$nav.toggleClass('is-gone', scrollTop > prev);
				prev = scrollTop;
			}
		});
	}
    
	////////////////////////
	// Mobile Nav Primary //
	////////////////////////

	// Mobile Nav Primary
	function navMobilePrimary(){
		var $navMobilePrimary = $('.js-navMobilePrimary'),
			$menuInsertSelector = $('.js-mainWrapper'),
			$pageSelector = '.js-innerWrapper',
			stickyOffset = getElementStyleValue('.js-mainLayout','margin-top');

		$navMobilePrimary.mmenu({
			className: {
				selected: "active"
			},
			navbar: {
				add: false
			},
			//sub-menu slide or drop-down ?
			slidingSubmenus: false,
	    	extensions: [
				"position-back",
            	"position-right",
			]
		}, {
	    // configuration
        	offCanvas: {
        		menuInsertSelector: $menuInsertSelector,
                pageSelector: $pageSelector
			},
	        classNames: {
				fixedElements: {
					fixed: "u-isFixed",
					sticky: "is-sticky"
				}
			}
		});

	    var $navTrigger = $( '.js-navMobileTrigger' ),
	    	API = $navMobilePrimary.data( "mmenu" );

	    $navTrigger.on( "click", function(e) {
	    	e.preventDefault();
	    	var $this = $(this);
	    	if ($this.hasClass('is-active')) {
	    		API.close();
	    	} else {
	    		API.open();
	    	}
		});

		// Close if clicked outside nav
		$(document).click(function (e){
			if (!$navMobilePrimary.is(e.target) && $navMobilePrimary.has(e.target).length === 0){
				API.close();
			}
		});
		

		API.bind( "open:finish", function() {
			setTimeout(function() {
				$navTrigger.addClass( "is-active" );
			}, 50);
		});
		API.bind( "close:finish", function() {
			setTimeout(function() {
				$navTrigger.removeClass( "is-active" );
			}, 50);
		});

	}

	//////////////
	// Lightbox //
	//////////////
	function lightBox(){
		$('a[href$="jpg"], a[href$="png"], a[href$="jpeg"], .js-mediaBox').lightcase({
			maxWidth: 1280,
			maxHeight: 1024,
			slideshow: true,
			slideshowAutoStart: false
		});
	}

	////////////
	// Slider //
	////////////
	function slider(){
		var $slider = $('.js-slider--diaporama');

		$slider.slick({
			dots: true,
			arrows: true,
			infinite: true,
			speed: 1500,
			autoplay: true,
			autoplaySpeed: 4000,
			fade: true,
			cssEase: 'ease',
			slidesToShow: 1,
  			slidesToScroll: 1
		});
	}
	/** 
	 * Carousel (pour single gallery par exemple)
	*/
	function carousel(){
		var $carousel = $('.js-gallery .gallery');
		$carousel.slick({
			dots: true,
			arrows: true,
			infinite: true,
			speed: 1500,
			autoplay: true,
			autoplaySpeed: 4000,
			fade: true,
			cssEase: 'ease',
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
			prevArrow:"<button type='button' class='c-Carousel-prev'><span class='o-Icon'></span></button>",
			nextArrow:"<button type='button' class='c-Carousel-next'><span class='o-Icon'></span></button>",
			responsive: [
				
				{
				  breakpoint: 600,
				  settings: {
					arrows: true
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					arrows: false,
					autoplay: false,
				  }
				}]
		});
	}
	function setCaptionInCarousel(){
		// adapte la largeur de la légende à l'image
		$('.slick-slider .gallery-item').each( function (){
			var mySize = $(this).find('.c-Carousel-image').width();
			$(this).find('.gallery-caption').attr('style','width:'+mySize+'px;margin: 0 auto;');
		});
	}


	/**
	 * use child <img> for bgUrl
	 */
	function imgLiquid(){
		$(".js-imgBkg").imgLiquid({
		 fill: true,
			 verticalAlign:  'center',
			 horizontalAlign: 'center'
		});
	 }

	 // adapte la position de la legende en single sur mobile

	 function switchImageLegend(){
		if (Foundation.MediaQuery.is('small only')) {
			$('.c-Article-imageFeatured--full').after( $('.o-Figcaption'));
		  }
	 }

	function addAds(){
		var $ads = $(".js-Annonces"),
			$adsPlaceholder = $(".js-AnnoncesPlaceholder");

		$adsPlaceholder.append($ads);
	}

	function aboBox(){

		// seulement pour les boites AboBox (sur la page abonnements)
        if( $('.c-Abobox').length ){
            // active le premier element de la boite par defaut
            $('.c-Abobox-variations form').each(function(){
                $(this).find(">:first-child").addClass('is_selected');
            });
            // change la couleur de fond quand on clique sur une ligne
            $(document).on('change', '.variation_radio', function (e) {
                $(this).parents('label').siblings().removeClass('is_selected');
                $(this).parents('label').addClass('is_selected');
            });
            // Mise en évidence d'une offre promotionnelle
            if( $('.c-Abobox-variation-check del').length ){
                $('.c-Abobox-variation-check del').parents('label').addClass('is_promo');
            }
        }
	}

	function modaleAutofocus(){
		$(document).on('open.zf.reveal', '[data-reveal]', function () {
			//console.log('opened!')
			var modal = $(this);
			modal.find('[autofocus]').focus();
		});
	}

	function modalLogin() {
	 
		// Perform AJAX login on form submit
		$('form#c-Modal-login').on('submit', function(e){
			$('.c-Modal-login-status').show().text(ajax_login_object.loadingmessage);
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: ajax_login_object.ajaxurl,
				data: { 
					'action': 'ajaxlogin', //calls wp_ajax_nopriv_ajaxlogin
					'username': $('form#c-Modal-login #username').val(), 
					'password': $('form#c-Modal-login #password').val(), 
					'security': $('form#c-Modal-login #security').val() },
				success: function(data){
					$('.c-Modal-login-status').text(data.message);
					if (data.loggedin === true){
						document.location.href = ajax_login_object.redirecturl;
					}
				}
			});
			e.preventDefault();
		});
	 
	}

	////////////////////
	// sticky footer //
	////////////////////
	function stickyFooter(){
		var $windowHeight = $(window).height(),
			$footer = $(".js-footerSite"),
			footerPos = $footer.position(),
			contentHeight = $windowHeight - footerPos.top,
			emptySpace = contentHeight - $footer.height();

		if (emptySpace > 0) {
		   $footer.css({
		        'margin-top': emptySpace / baseFontSize + 'rem'
		   });
		}
	}
	
	/*===============================================================
	=       Main Func: initialize values and call initializers      =
	===============================================================*/
	function initPage(){
		
		addAds();
		aboBox();

	    // Init lightbox
		lightBox();
		
		modaleAutofocus();

		modalLogin();

		// img bkg
		imgLiquid();
		
		// 
		switchImageLegend();

		// sticky Mini-Nav
		miniNav();

		// Mobile Nav
		navMobilePrimary();
		
		// Carousel
		carousel();
		setCaptionInCarousel();
	    // Trigger resize
		$(window).trigger('resize');
    
	}
		
	/*==========================================================
	=       when adjust/change  window size                    =
	==========================================================*/
	$(window).resize(function(){
		// (re)claculate header-Site when sticky nav
		setHeaderSiteHeight();

		//(re)calculate sticky-footer
		stickyFooter();

	});

	/*==========================================================
	=             init main func (doc ready jquery)            =
	==========================================================*/
	// IE flexbox polyfill
	//flexibility(document.body);
	
	$(document).foundation();
	//$(document).flexibility();
	$(document).ready(initPage);


})(jQuery);